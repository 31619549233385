/**
 * This file contains all the static text used in the application.
 */
export function useText() {
  const staticText = ref({
    title: 'Hello World',
    app: {
      search: 'Search',
      organizedBy: 'Organized by',
      fundedBy: 'Funded by',
      distinguishedBy: 'Distinguished by',
      insights: 'Insights',
      events: 'Events',
      menu: 'Menu',
      news: 'News',
      publications: 'Publications',
      series: 'Series',
      filter: 'Filter',
      activeFilters: 'Active Filters',
      hitsPerPage: 'Hits per page',
      sortBy: 'Sort by',
      backToOverview: 'Back to overview',
      backToTop: 'Back to top',
      contribInstitutions: 'Contributing Institutions',
      contributions: 'Contributions',
      resources: 'Resources',
      enableMap: 'Enable Map',
      expandAll: 'Expand All',
      readMore: 'Read More',
      contribute: 'Contribute',
    },
    frontpage: {
      whatwedo: 'What makes us special',
      about: 'Your central hub for global and area studies',
      aboutSubtitle:
        'Explore and share a new way to access scholarly knowledge',
      highlights: 'Highlights',
      whatwedoText: '',
      whatlooking: 'What are you looking for?',
    },
    portal: {
      relSeries: 'Series',
      relEntries: 'Related Entries',
      relDossiers: 'Related Dossiers',
      published: 'Published:',
      details: 'Classification',
      contributors: 'Contributors',
      institutions: 'Institutions',
      resourceURL: 'Original URL',
      originalTitle: 'Original Title',
      created: 'Created',
      updated: 'Updated',
      submittedBy: 'Submitted by',
      subtitles: 'Subtitles',
      topics: 'Topics',
      topicsMain: 'Subject Areas',
      timePeriods: 'Centuries',
      targetAudiences: 'Target Audiences',
      subnationalEntities: 'Localities',
      spatialSemantics: 'Spatial Semantics',
      space: 'Space',
      fieldOfKnowledge: 'Field of Knowledge',
      disciplines: 'Disciplines',
      continents: 'Continents',
      countries: 'Countries',
      fileFormat: 'File Format',
      type: 'Type',
      originalEventDate: 'Original Event Date',
      videoCategory: 'Video Formats',
      audioCategory: 'Audio Formats',
      textCategory: 'Text Formats',
      participants: 'Participants',
      fileLength: 'File Length',
      scholars: 'Scholars',
      summary: 'English Executive Summary',
      subjectAreas: 'Subject Areas & Time Periods',
      locationSpaces: 'Location & Spaces',
      contributorsAudiences: 'Contributors & Audiences',
      mediaInfo: 'Media & Info',
      language: 'Language',
      publicationYear: 'Publication Year',
    },
    people: {
      relatedEvents: 'Related Events',
      relatedPublications: 'Related Publications',
      relatedParticipations: 'Related Participations',
      relatedInstitutions: 'Related Institutions',
      relatedDossiers: 'Related Dossiers',
    },
    institutions: {
      relatedResources: 'Related Resources',
      relatedPeople: 'Related People',
    },
    sections: {
      latestResources: 'Latest Resources',
      latestPublications: 'Latest Publications',
      latestNews: 'Latest News',
      latestEvents: 'Latest Events',
      latestDossiers: 'Latest Dossiers',
      relatedInstitutions: 'Related Institutions',
    },
    publications: {
      latest: 'Latest Publications',
      all: 'All Publications',
    },
    news: {
      authors: 'Authors',
      venue: 'Venue',
      contact: 'Contact',
      furtherInformation: 'Further Information',
      language: 'Language',
    },
    cta: {
      explore: 'Explore',
      viewAll: 'View All',
      viewAllData: 'See All Resources',
      viewAllPublications: 'See All Publications',
      viewAllNews: 'See All News',
      viewAllEvents: 'See All Events',
      viewAllDossiers: 'See All Dossiers',
    },
    dossiers: {
      latest: 'Latest Dossiers',
      toc: 'Table of Contents',
      all: 'All Dossiers',
      footnotes: 'Footnotes',
      imageCredits: 'Image Credits',
    },
  })

  return {
    staticText,
  }
}
